<template>
  <div>
    <c-table
      ref="table"
      title="위험등록부 목록"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="gridRisk.data" 
      :merge="gridRisk.merge"
      :editable="editable"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramThirdAssessScenarioId"
            ibmTaskTypeCd="ITT0000013"
            ibmTaskUnderTypeCd="ITTU000015"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'assessRiskRegisterStatus',
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: null,
        assessmentDates: [],
        deptCd: null,
        ibmStepCd: null,
      }),
    },
  },
  data() {
    return {
      gridRisk: {
        merge: [
          { index: 0, colName: 'ramRiskAssessmentPlanId' },
          { index: 1, colName: 'ramRiskAssessmentPlanId' },
          { index: 2, colName: 'processCd' },
          { index: 3, colName: 'processCd' },
        ],
        data: [],
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            // 평가명
            label: 'LBL0000367',
            align: 'left',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            // 평가구분
            label: 'LBL0000608',
            align: 'center',
            style: 'width:70px',
            sortable: false
          },
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessDeptName',
            field: 'assessDeptName',
            label: '평가부서',
            align: 'center',
            style: 'width:80px',
          },
          {
            name: 'assessItem',
            field: 'assessItem',
            label: '재해유형 / 유해위험요인',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskRegister.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridRisk.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0001040'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        ramStepCd: row.ramStepCd,
        vendorFlag: row ? (row.vendorFlag > 0) : false,
        ramTechniqueCd: row.ramTechniqueCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>